import React from "react";
import "./index.css";
import MyRoutes from "./MyRoutes";

function App() {
  return (
    <>
      <MyRoutes />
    </>
  );
}

export default App;
