import { Box, Container } from "@mui/material";
import React from "react";
import ComingSoon from "../../components/website/comingSoon/ComingSoon";

const PricingsPage = () => {
  return (
    <>
      <Box>
          <ComingSoon />
      </Box>
    </>
  );
};

export default PricingsPage;
