import styled from "styled-components";
import MrSolution from "../../../assest/images/MrSolution.png";

const LoginWrapper = styled.section`
  height: 100dvh;
  background-color: var(--primary--200);
  position: relative;
  z-index: 3;
  .login-wrapper::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: url(${MrSolution});
    background-position: right 25% top 2%;
    background-repeat: no-repeat;
    background-size: 20%;
    -webkit-animation: bounce-in-top 1.1s both;
    animation: bounce-in-top 1.1s both;
    z-index: -1;
  }

  /* .particle{
    position: absolute;
    z-index: -2;
  } */

  .our-services-card {
    background-color: var(--primary--300);
  }

  .login-wrapper {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
  }

  .software-list {
    font-size: 20px;
    font-weight: 600;
    color: var(--primary--900);
    margin: 0 0 5px 0;
  }

  .icons-display {
    color: var(--primary--900);
    margin: 0 10px 0 0;
  }

  .login-section {
    display: flex;
    justify-content: center;
  }

  .text {
    color: var(--primary--800);
  }

  .card-style {
    display: flex;
    flex-direction: column;
    width: 500px;
  }

  .card-header {
    text-align: center;
    font-size: 18px;
    color: var(--primary--900);
    font-weight: bold;
  }

  .password-input-section {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .text-field {
    width: 90%;
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: var(--primary--900);
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--primary--800);
  }

  /* animation bounce-in-top */
  @-webkit-keyframes bounce-in-top {
    0% {
      -webkit-transform: translateY(-500px);
      transform: translateY(-500px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      opacity: 0;
    }
    38% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
      opacity: 1;
    }
    55% {
      -webkit-transform: translateY(-65px);
      transform: translateY(-65px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    72% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
    81% {
      -webkit-transform: translateY(-28px);
      transform: translateY(-28px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    90% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
    95% {
      -webkit-transform: translateY(-8px);
      transform: translateY(-8px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
  }
  @keyframes bounce-in-top {
    0% {
      -webkit-transform: translateY(-500px);
      transform: translateY(-500px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      opacity: 0;
    }
    38% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
      opacity: 1;
    }
    55% {
      -webkit-transform: translateY(-65px);
      transform: translateY(-65px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    72% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
    81% {
      -webkit-transform: translateY(-28px);
      transform: translateY(-28px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    90% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
    95% {
      -webkit-transform: translateY(-8px);
      transform: translateY(-8px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
  }
`;

export default LoginWrapper;
