import styled from "styled-components";

const FromsWrapper = styled.section`
  .form-control {
    display: flex ;
    align-items: center;
    width: 100%;
    background-color: transparent;
  }
  .form-label {
    color: var(--tertairy-color);
    font-size: 1.1rem;
  }
  .text-field{
    margin-left: 10px;
    width: 50%;
  }
`;

export default FromsWrapper;
